<template>
    <div>
        <recess-table-flex 
            class="qa-table-order-fee-list" 
            :table-options="setTableOptions"
            :pagination-options="setPaginationOptions"
            @getData="updateList"
        >
            <recess-table-flex-head :set-fixed-header="true">
                <recess-table-flex-row>
                    <recess-table-flex-head-item
                        v-for="(th, index) in thead"
                        :key="index"
                        :class="th.className"
                        >{{ th.title }}</recess-table-flex-head-item
                    >
                </recess-table-flex-row>
            </recess-table-flex-head>

            <recess-table-flex-body>
                <recess-table-flex-row v-for="(tr, indextr) in orderFees" :key="indextr">
                    <recess-table-flex-cell
                        :data-label="thead[0].title"
                        class="u-cursor-pointer qa-order-fee-list-order-number"
                        @click.native="goToOrderDetails(tr.document.id)"
                    >
                        <a>{{ tr.document.orderNumber }}</a>
                    </recess-table-flex-cell>
                    <recess-table-flex-cell
                        :data-label="thead[1].title"
                        class="qa-order-fee-list-total-fee-amount"
                        >{{ tr.document.totalFeeAmount | currency }}</recess-table-flex-cell
                    >
                    <recess-table-flex-cell
                        :data-label="thead[2].title"
                        class="qa-order-fee-list-date-created"
                        >{{ tr.document.dateCreated | formatDate }}</recess-table-flex-cell
                    >
                    <recess-table-flex-cell
                        :data-label="thead[3].title"
                        class="qa-order-fee-list-fee-status"
                    >
                        <span
                            :class="
                                toggleStatusDisplayValueCssClass(tr.document.statusDisplayValue)
                            "
                        >
                            {{ tr.document.statusDisplayValue }}
                        </span>
                    </recess-table-flex-cell>
                </recess-table-flex-row>
            </recess-table-flex-body>
         
        </recess-table-flex>
    </div>
</template>

<script>
import { getItemsFromSearchIndex } from '@/../../shared/api/SharedClient'
import { PAGINATION, API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { toISODateString, toISODateStringLastDay } from '@/utils/dateTimeHelper'
 
import tableMixin from '@/../../shared/mixins/tableMixin' 

export default {
    name: 'OrderFeeList', 
    mixins: [tableMixin],
    props: {
        setSelectedFilters: {
            type: Object,
            default:() => {
                return {}
            }
        }
    },
    data() {
        let paginationOptions = [
            { displayText: '5', value: 5 },
            { displayText: '10', value: 10 },
            { displayText: '25', value: 25 },
            { displayText: '50', value: 50 },
            { displayText: '100', value: 100 }, 
            { displayText: '250', value: 250 }
        ]

        return {
            PAGINATION,
            PROVIDER,
            orderFees: [],
            thead: [
                {
                    title: PROVIDER.ResultsAndContractsPage.OrderFeesPage.TableHeaders.Order,
                    sortKey: 'orderNumber'
                },
                {
                    title: PROVIDER.ResultsAndContractsPage.OrderFeesPage.TableHeaders.Price,
                    sortKey: 'price'
                },
                {
                    title: PROVIDER.ResultsAndContractsPage.OrderFeesPage.TableHeaders.DateCreated,
                    sortKey: 'dateCreated'
                },
                {
                    title: PROVIDER.ResultsAndContractsPage.OrderFeesPage.TableHeaders
                        .OrderFeeStatus,
                    sortKey: 'status'
                }
            ],
            filter: {
                search: '',
                top: 0,
                skip: 0,
                orderBy: ['dateCreated desc'],
                filter: ''
            },
            
            // TABLE AND PAGINATION OPTIONS - REQUIRED
            setTableOptions: {
                title: PROVIDER.ResultsAndContractsPage.OrderFeesPage.TableTitle,
                setCellSizes: true 
            },
            setPaginationOptions: { 
                showTopPagination: true, 
                showBottomPagination: true, 
                currentPage: this.$route.query.page !== undefined ? Math.round(Number(this.$route.query.page)) : 1,
                totalCount: null,
                selectOptions: paginationOptions,
                defaultTotalItemsPerPage: paginationOptions[2].value,
                itemsPerPageFromUrlQuery: this.$route.query.itemsPerPage !== undefined ? Number(this.$route.query.itemsPerPage) : paginationOptions[2].value,
                showSelectOption: true, 
                selectOptionsLabel: PAGINATION.selectOptionsLabel,
                paginationShowLabel: PAGINATION.paginationShowLabel,
                paginationOfLabel: PAGINATION.paginationOfLabel 
            } 
        }
    }, 
    beforeDestroy() { 
        clearTimeout(this.timeout)
    },
    methods: {
        toggleStatusDisplayValueCssClass(statusDisplayValue) {
            let cssClass
            switch (statusDisplayValue) {
                case PROVIDER.ResultsAndContractsPage.OrderFeesPage.Status.NotInvoiced:
                    cssClass = 'u-color-error'
                    break
                case PROVIDER.ResultsAndContractsPage.OrderFeesPage.Status.Invoiced:
                    cssClass = 'u-color-success'
                    break
                case PROVIDER.ResultsAndContractsPage.OrderFeesPage.Status.Credited:
                    cssClass = 'u-color-warning'
                    break
                default:
                    cssClass = null
            }
            return cssClass
        },
        goToOrderDetails(orderId) {
            if (!orderId) return

            this.$router.push({
                name: 'order-details',
                params: { id: orderId, prevPath: this.$route.name }
            })
        },
        updateList({ currentPage, itemsPerPage }) {
            this.setPaginationOptions.itemsPerPageFromUrlQuery = itemsPerPage
            this.setPaginationOptions.currentPage = currentPage
 
            this.filter.top = itemsPerPage
            this.filter.skip = currentPage * itemsPerPage - itemsPerPage 
            this.filter.filter = ''

            this.getSelectedFeeStatus()
            this.getSelectedProvider()
            this.getSelectedStartDateCreated()
            this.getSelectedEndDateCreated() 
            
            // Get data from api
            this.getDataOnPageChange()
        },
        concatenateFilterString() {
            if (this.filter.filter.length > 0) {
                this.filter.filter += ` and `
            }
        },
        getSelectedFeeStatus() {
            // if user selects 'ALL' for fee status
            if (this.setSelectedFilters.feeStatus && this.setSelectedFilters.feeStatus !== 'All') {
                // only filter by fee status
                this.filter.filter = `status eq '${this.setSelectedFilters.feeStatus}'`
            }
        },
        getSelectedProvider() {
            if (!(this.setSelectedFilters && this.setSelectedFilters.providerId)) return

            this.concatenateFilterString()
            this.filter.filter += `providerId eq '${this.setSelectedFilters.providerId}'`
        },
        getSelectedStartDateCreated() {
            if (!this.setSelectedFilters.minDateCreated) return

            const startDate = toISODateString(this.setSelectedFilters.minDateCreated)

            this.concatenateFilterString()

            this.filter.filter += `dateCreated ge ${startDate}`
        },
        getSelectedEndDateCreated() {
            if (!this.setSelectedFilters.maxDateCreated) return
            
            const endDate = toISODateStringLastDay(this.setSelectedFilters.maxDateCreated)
            
            this.concatenateFilterString()

            this.filter.filter += `dateCreated lt ${endDate}`
        },
        getDataOnPageChange() {
           this.getOrderFeeList()
           this.setCurrentPageAsQueryParam()
        },
        async getOrderFeeList() {
            try {
                const response = await getItemsFromSearchIndex(
                    process.env.VUE_APP_ORDER_API_URL,
                    API_CALL_URL_PATHS.orders,
                    API_CALL_URL_PATHS.searchOrderFees,
                    this.filter
                )
                if (!response) return
                this.orderFees = response.results 
                
                this.setPaginationOptions.totalCount = response.count
            } catch (error) {
                console.error('Something went wrong while retrieving order fees', error)
            }
        }
    }
}
</script>
